import React from 'react';
import Seo from '../components/seo';
import PrivacySec from '../containers/Privacy';
import { ResetCSS } from 'common/src/assets/css/style';

const PrivacyPage = () => (
  <>
    <Seo title="Privacy Policy" />
    <ResetCSS />
    <PrivacySec></PrivacySec>
  </>
);

export default PrivacyPage;
